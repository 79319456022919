import Request from '@/utils/request.js'

// 微信分享卡片
function wxShare(data) {
  const request = new Request()
  return request.get({
    url: '/user/wechat/getShareToken',
    data
  })
}

export { wxShare }
