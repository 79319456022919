// import { gotoLogin, event } from './util.js'

class requestError extends Error {
  constructor({ errno, msg }) {
    super(msg)
    this.errno = errno
    this.msg = msg
  }
}

function strinify(data) {
  let result = []
  for (const key in data) {
    if (!Object.hasOwnProperty.call(data, key)) continue
    let v = data[key]
    if (v === null || v === undefined) {
      continue
    }

    result.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
  }
  return result.join('&')
}

function makeUrl(url, data = {}) {
  data.t = new Date().getTime()

  let join = url.indexOf('?') > 0 ? '&' : '?'
  url = `${url}${join}${strinify(data)}`
  return url
}

function qsTransform(data) {
  let str = ''
  if (typeof data !== 'object' && !Array.isArray(data)) {
    return ''
  }

  Object.keys(data).forEach((key, index, arr) => {
    if (index === arr.length - 1) {
      str += `${key}=${qsString(data[key])}`
    } else {
      str += `${key}=${qsString(data[key])}&`
    }
  })

  return str
}

function qsString(data) {
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  return encodeURIComponent(data)
}

function proxyWithTest(url) {
  const isTest = location.hostname.indexOf('lap') > -1
  if (isTest) {
    // lap 只有当请求是 api 开头的，才转发 cookie
    return `/api${url}`
  }
  return proxyUrl(url)
}

function proxyUrl(url) {
  return `/webapi${url}`
}

class Request {
  constructor({ timeout = 50000 } = {}) {
    let xhr = new XMLHttpRequest()
    this.timeout = timeout
    this.xhr = xhr
    this.hasAborted = false
  }

  setTimeout() {
    this.xhr.timeout = this.timeout
  }

  _getResponse() {
    let xhr = this.xhr
    return new Promise((resolve, reject) => {
      xhr.ontimeout = function () {
        reject(
          new requestError({
            errno: 1,
            msg: 'timeout'
          })
        )
      }

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200 || xhr.status === 304) {
            let d
            try {
              d = JSON.parse(xhr.responseText)
            } catch (e) {
              console.log(e)
              console.log(xhr.responseText)
              return
            }
            if (d.errno === 0) {
              resolve(d)
            } else if (d.errno == 100102) {
              let href = window.location.href
              if (/showroom\.html/.test(href)) {
                //什么也不做
              } else if (/\/wap\//.test(href)) {
                // gotoLogin()
              } else {
                /* href = href.split(/[#?]/)[0].replace('//', '')
                let index = href.indexOf('/')
                href = href.substr(index)
                 */
                event.$emit('request.login')
              }

              return
            } else {
              reject(
                new requestError({
                  errno: d.errno,
                  msg: d.msg
                })
              )
            }
          } else if (xhr.status != 0) {
            reject(
              new requestError({
                errno: 2,
                msg: '网络错误'
              })
            )
          } else if (!this.hasAborted) {
            console.error('系统开了个小差，请稍候重试', `xhr.status ${xhr.status}`)
            reject(
              new requestError({
                errno: 3,
                msg: '未知错误'
              })
            )
          }
        }
      }.bind(this)
    })
  }

  get({ url, data = {} }) {
    url = proxyWithTest(url)
    let xhr = this.xhr

    url = makeUrl(url, data)

    xhr.open('GET', url)
    this.setTimeout()
    xhr.withCredentials = true
    let res = this._getResponse()
    xhr.send()
    return res
  }

  post({ url, data = {} }) {
    url = proxyWithTest(url)
    let xhr = this.xhr
    xhr.open('POST', url)
    this.setTimeout()
    xhr.withCredentials = true
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    let res = this._getResponse()
    xhr.send(qsTransform(data))
    return res
  }

  abort() {
    this.hasAborted = true
    this.xhr.abort()
  }
}

export class DevRequest extends Request {
  get({ url, data = {} }) {
    return super.get({
      url,
      data: Object.assign(data, { debug: 1 })
    })
  }

  post({ url, data = {} }) {
    return super.post({
      url,
      data: Object.assign(data, { debug: 1 })
    })
  }
}

function jsonp(url, data = {}) {
  return new Promise((resolve, reject) => {
    let dataString = url.indexOf('?') === -1 ? '?' : '&'
    let callbackName = `jsonpCB_${Date.now()}`
    url += `${dataString}callback=${callbackName}`
    if (data) {
      for (let k in data) {
        url += `&${k}=${data[k]}`
      }
    }
    let jsNode = document.createElement('script')
    jsNode.src = url
    window[callbackName] = (res) => {
      delete window[callbackName]
      document.body.removeChild(jsNode)
      if (res.errno === 0) {
        resolve(res.data)
      } else {
        reject(res.errmsg)
      }
    }
    jsNode.addEventListener(
      'error',
      () => {
        delete window[callbackName]
        document.body.removeChild(jsNode)
        reject('js加载资源失败')
      },
      false
    )
    document.body.appendChild(jsNode)
  })
}

export default Request

export { jsonp, requestError }
