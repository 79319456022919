import { wxShare } from '@/api/common/index.js'
const wx = window.wx

function initWeixinApi(e, data) {
  wx.config({
    debug: false,
    appId: e.appId,
    timestamp: e.timestamp,
    nonceStr: e.nonceStr,
    signature: e.signature,
    jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage']
  })
  // sdk 权限验证配置成功回调
  wx.ready(function () {
    console.log('wx.ready success')
  })
  // sdk 权限验证配置失败回调
  wx.error(function (res) {
    console.log('wx.error', res)
  })

  wxReady(data)
}

function wxReady(wxData) {
  wxData.title = wxData.title || document.title
  wxData.link = wxData.link || window.location.href
  wx.ready(function () {
    wx.onMenuShareTimeline({
      title: wxData.title,
      link: wxData.link,
      imgUrl: wxData.imgUrl,
      trigger: function () {},
      cancel: function (e) {
        console.log(e)
      },
      fail: function (e) {
        console.log(e)
      },
      success: function (e) {
        console.log(e)
      },
      complete: function (e) {
        console.log(e)
      }
    })

    wx.onMenuShareAppMessage({
      title: wxData.title,
      desc: wxData.desc,
      link: wxData.link,
      imgUrl: wxData.imgUrl,
      trigger: function () {},
      cancel: function (e) {
        console.log(e)
      },
      fail: function (e) {
        console.log(e)
      },
      success: function (e) {
        console.log(e)
      },
      complete: function (e) {
        console.log(e)
      }
    })
  })
}

const share = function (data) {
  wxShare({
    appid: 'wx2d0fca3aa166d52e',
    url: encodeURIComponent(window.location.href.split('#')[0])
  })
    .then((res) => {
      initWeixinApi(res.data, data)
    })
    .catch((e) => {
      console.log(e)
    })
}

export default share
